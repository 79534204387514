#qr-img1 {
    text-align: center;
    width: 435px;
    height: 630px;
    margin: 15px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 720px 630px;
}

#qr-img2 {
    text-align: center;
    width: 435px;
    height: 620px;
    margin: 15px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 720px 630px;
}

#qr-img3 {
    text-align: center;
    width: 435px;
    height: 630px;
    margin: 15px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 720px 630px;
}

#qrcode1 {
    height: 258px;
    width: 205px;
}

#qrcode2 {
    height: 233px;
    width: 213px;
}

#qrcode3 {
    height: 265px;
    width: 256px;
}

.logos1 {
    /* margin-top: 22px; */
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.logos1 img {
    width: 110px;
}

.logos2 {
    /* margin-top: 20px; */
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.logos2 img {
    width: 110px;
}

.logos3 {
    margin-top: 15px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.logos3 img {
    width: 110px;
}


.table_QRmain{
    padding: 10px;
    background-color:#fff;
    height: 700px;
    width: 700px; 
    display: none;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-wrap: nowrap; 
}

.table_QRsub{
    height: 90%;
    width: 85%;
    background-color: black;
    border-radius: 50px;
    padding: "20px";
    margin: "5rem";
    text-align: "center";
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-wrap: nowrap;

}
.table_QRsub h1{
    color: #fff;
    font-size: 60px;
    margin: 0px;
}

.tableQRCode{
    background-color: #fff;
    padding: 6px;
    width: 70%;
    height: 60%;
    border-radius: 50px;

}
.tableQRCode p{
    margin: 0px;

}
.logos{
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.logos img{
    width: 160px;
}



@media (max-width: 480px) {

    #qr-img1,
    #qr-img2,
    #qr-img3 {
        width: 100%;
        background-size: cover;
    }

    #qrcode2{
        height: 300px;
        width: 204px;
    }
    #qrcode1,
    #qrcode3 {
        height: 300px;
        width: 204px;
    }
    .logos3 {
        /* top: 250px;
        gap: 10px; */
    }
    .logos1 img,
    .logos2 img,
    .logos3 img {
        width: 80px;
    }

    .logos1,
    .logos2 {
        /* top: 210x; */
        /* flex-direction: ; */
        gap: 10px;
    }
}